@use "../../scss/abstracts/" as *;

html {
  background-color: var(--bgc);
}

.container {
  @include flex();
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bgc);
  position: relative;
}

.loginBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  .iitLogo {
    margin-bottom: 1rem;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }

  & input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: #f9f9fa;
    font-size: 1rem;
    color: #333;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  .errorMessage {
    font-size: 0.9rem;
    color: red;
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    padding: 12px;
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #ff3333;
    }
  }

  .signup {
    margin-top: 1rem;
    font-size: 15px;
    text-decoration: none;
  }

  .forgat_pass {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    input {
      margin-right: 0.5rem;
    }

    label {
      font-size: 0.9rem;
    }
  }
}

.virtualLabsLogoContainer {
  margin-left: 80px;
}


.rtl {
  font-family: iranyekan, "IRANSans", "Tahoma";
  direction: rtl;
  text-align: right;

  input, button, label {
    text-align: right;
  }
}
