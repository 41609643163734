.sidebar {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgc);
    color: var(--text-color) !important;
    width: 235px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.5s ease, margin-left 0.5s ease;
    z-index: 200;
    overflow-y: auto;
    overflow-x: hidden;
}
.sidebar.sidebar_close {
    width: 50px;
}

.sidebar.sidebar_close .sidebar__logo img {
    width: 30px;
    height: 30px;
    transition: width 1s ease, height 1s ease;
}

.sidebar__logo {
    text-align: center;
    padding: 1rem;
}

.sidebar__logo img {
    width: 120px;
    height: 120px;
    transition: width 0.3s ease, height 0.3s ease;
}

.sidebar__menu {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
}

.sidebar__menu__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 2rem;
    padding-left: 16px;
    transition: all 0.3s ease-in-out;
}

.sidebar__menu__item:hover {
    color: var(--primaryColor);
}

.sidebar__menu__item.active {
    font-weight: 700;
    color: var(--primaryColor);
    padding-left: 20px;
}

.sidebar__menu__item.active::before {
    content: "";
    width: 4px;
    height: 100%;
    background-color: var(--primaryColor);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
}

.sidebar__menu__item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.sidebar__menu__item__icon svg {
    width: 24px;
    height: 24px;
}

.sidebar__menu__item__txt {
    white-space: nowrap;
}

.submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-left: 60px;
}

.submenu.submenu_open {
    max-height: 200px;
    display: block;
}

.sidebar__submenu__item {
    padding: 5px 0;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.3s ease;
}

.sidebar__submenu__item:hover {
    color: var(--primaryColor);
}

.arrow {
    transition: transform 0.3s ease;
    margin-left: auto;
}

.arrow.open {
    transform: rotate(180deg);
}

.main-content {
    margin-left: 235px;
    transition: margin-left 0.5s ease;
}

.sidebar_close + .main-content {
    margin-left: 50px;
}

.logout {
    align-self: flex-end;
}

html[dir="rtl"] .sidebar__menu {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
}

html[dir="rtl"] .sidebar__menu__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 2rem;
    padding-left: 16px;
    transition: all 0.3s ease-in-out;
}

html[dir="rtl"] .sidebar__menu__item:hover {
    color: var(--primaryColor);
}

html[dir="rtl"] .sidebar__menu__item.active {
    font-weight: 700;
    color: var(--primaryColor);
    padding-left: 20px;
}

html[dir="rtl"] .sidebar__menu__item.active::before {
    content: "";
    width: 4px;
    height: 100%;
    background-color: var(--primaryColor);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
}

html[dir="rtl"] .sidebar__menu__item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

html[dir="rtl"] .sidebar__menu__item__icon svg {
    width: 24px;
    height: 24px;
}

html[dir="rtl"] .sidebar__menu__item__txt {
    white-space: nowrap;
}

html[dir="rtl"] .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-left: 20px;
}

html[dir="rtl"] .submenu.submenu_open {
    max-height: 200px;
}

html[dir="rtl"] .sidebar__submenu__item {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.3s ease;
}

html[dir="rtl"] .sidebar__submenu__item:hover {
    color: var(--primaryColor);
}

html[dir="rtl"] .arrow {
    transition: transform 0.3s ease;
    margin-left: auto;
}

html[dir="rtl"] .arrow.open {
    transform: rotate(180deg);
}
