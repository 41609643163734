/* Container for the entire task assignment section */
.assign-task {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    transition: backdrop-filter 0.3s ease-in-out; /* Smooth transition for the blur */
}

/* Heading styling */
.assign-task h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.8em;
    font-weight: 600;
}

/* Form layout */
.assign-task form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Label styling */
.assign-task label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #333;
    font-size: 1em;
}

/* General input styling for select, date, text, textarea, and button */
.assign-task select,
.assign-task input[type="date"],
.assign-task input[type="text"],
.assign-task textarea,
.assign-task button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-top: 5px;
}

/* Select and input fields styling */
.assign-task select {
    width: 100%;
}

/* Date input styling for DOA and Deadline on a single line */
.date-inputs {
    display: flex;
    gap: 20px;
}

.date-inputs label {
    flex: 1;
}

/* Textarea styling with vertical resizing and min height */
.assign-task textarea {
    resize: vertical;
    min-height: 80px;
    width: 100%;
}

/* Styling for the submit button */
.assign-task button[type="submit"] {
    background-color: #ff7225;
    color: #fff;
    border: none;
    text-align: center;
    justify-content: center;
    width: 160px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 12px;
    border-radius: 25px;
    display: inline-block; /* Ensures the button stays centered */
    text-align: center;
    margin-left: 160px;
    font-size: 1em;
}

/* Hover effect for the submit button */
.assign-task button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Disabled button styling */
.assign-task button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Error message styling */
.error {
    color: #d9534f;
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.9em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .assign-task {
        padding: 15px;
        margin: 30px auto;
    }

    .date-inputs {
        flex-direction: column;
        gap: 10px;
    }

    .assign-task button[type="submit"] {
        padding: 10px;
        font-size: 0.95em;
    }
}
