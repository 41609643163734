.sign-up-two {
    position: relative;
    min-height: 100vh;
    background: url('../../assets/images/bg.jpg') fixed center;
    background-size: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-two::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.sign-up-two form {
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.1); /* Translucent white */
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    width: 550px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 2px;
}

.file-upload-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.file-input-row {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
}

.file-label {
    min-width: 180px;
    font-weight: bold;
    color: #333;
    font-size: 1em;
}

.file-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Submit button styling */
.sign-up-two button[type="submit"] {
    background-color: #ff7225;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 25px;
    font-size: 1em;
    cursor: pointer;
    font-weight: bold;
    align-self: center;
    width: 160px;
    text-align: center;
    transition: background-color 0.3s;
}

.sign-up-two button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Error message styling */
.error {
    color: #d9534f;
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.9em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sign-up-two form {
        padding: 15px;
        margin: 30px auto;
    }

    .file-input-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .sign-up-two button[type="submit"] {
        padding: 10px;
        font-size: 0.95em;
    }
}
