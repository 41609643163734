@use "../../scss/abstracts/" as *;

.topNav {
  display: flex;
  justify-content: space-between; // Distribute items across the nav
  align-items: center; // Vertically align items
  padding: 0 1rem;
  border-bottom: 3px solid gainsboro;

  &_left {
    display: flex;
    align-items: center;

    &_menu_icon {
      cursor: pointer;
      @include tablet {
        display: none;
      }
    }
  }

  .notificationIcon {
    margin-left: auto; // Push to the right
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      width: 30px;
    }

    &.alert {
      color: red; // Red color if there's an unread notification
    }
  }

  &_right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

// Dialog Box Styles
.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialogBox {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;

  h3 {
    margin-bottom: 10px;
  }

  .notificationList {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    max-height: 300px;
    overflow-y: auto; /* Add scroll */
  }

  .notificationItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
    }
  }

  .dialogCloseButton {
    margin-top: 10px;
    padding: 8px 16px;
    background: #f41d23;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}

.error {
  color: red;
  margin-top: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
