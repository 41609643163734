/*.container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    min-height: 100vh;*/
/*    flex-direction: column;*/
/*}*/
/*.column-id {*/
/*    width: 100px;*/
/*}*/

/*.loading, .error {*/
/*    text-align: center;*/
/*    font-size: 1.5rem;*/
/*}*/

/*.data-table {*/
/*    width: 80%;*/
/*    border-spacing: 0;*/
/*    border-collapse: separate;*/
/*    border-radius: 15px;*/
/*    overflow: hidden;*/
/*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
/*}*/

/*.table-title {*/
/*    font-size: 1.75rem;*/
/*    font-weight: bold;*/
/*    color: #333;*/
/*    text-align: center;*/
/*    margin-bottom: 10px;*/
/*    padding: 10px 0;*/
/*    background-color: #f8f9fa;*/
/*}*/

/*.data-table th, .data-table td {*/
/*    padding: 12px 15px;*/
/*    text-align: left;*/
/*    border: 1px solid #ddd;*/
/*    transition: background-color 0.2s ease-in-out;*/
/*}*/

/*.data-table th {*/
/*    background-color: #007BFF;*/
/*    color: white;*/
/*    font-weight: bold;*/
/*    text-transform: uppercase;*/
/*    letter-spacing: 0.05rem;*/
/*    border: none;*/
/*}*/

/*.data-table tbody tr {*/
/*    background-color: #f8f9fa;*/
/*}*/

/*.data-table tbody tr:nth-child(even) {*/
/*    background-color: #e9ecef;*/
/*}*/

/*.data-table tbody tr:hover {*/
/*    background-color: #d1ecf1;*/
/*    cursor: pointer;*/
/*}*/

/*.data-table tbody tr:active {*/
/*    background-color: #c3e6cb;*/
/*    color: #155724;*/
/*}*/
/*.column-action {*/
/*    width: 20px; !* Adjust the width as needed *!*/
/*    text-align: center; !* Center-aligns the content if desired *!*/
/*}*/
/*.data-table button {*/
/*    background-color: transparent;*/
/*    border: none;*/
/*    padding: 0;*/
/*    cursor: pointer;*/
/*}*/

/*@media (max-width: 768px) {*/
/*    .data-table {*/
/*        width: 100%;*/
/*    }*/
/*}*/
.container {
    padding-left: 20px;
    margin-left: 60px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.dark .container {
    background-color: rgba(30, 37, 41, 0.8);
}

.table-title {
    width: 98.5%;
    font-size: 1.75rem;
    font-weight: bold;
    color: #333;
    background-color: transparent !important;
}

.dark .table-title {
    color: white;
    background: transparent !important;
}

.light .data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1rem;
    text-align: center;
    background-color: transparent !important; /* Keep table background transparent for blur effect */
}
.dark .data-table {
    width: 98.5%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1rem;
    text-align: center;
    background-color: transparent !important; /* Keep table background transparent for blur effect */
}

.data-table th,
.data-table td {
    padding: 12px;
    border: 1px solid #ddd;
    transition: background-color 0.2s ease-in-out;
}

.data-table th {
    background-color: #007bff; /* Set header background to blue */
    color: white;
}

tbody tr:nth-child(even) {
    background-color: rgba(249, 249, 249, 0.8); /* Light grey with transparency */
}

tbody tr:hover {
    background-color: rgba(241, 241, 241, 0.8); /* Slightly darker grey on hover */
}

.dark .data-table th {
    background-color: #022570;
    color: white;
}

.dark .data-table tbody tr {
    background-color: rgba(42, 42, 42, 0.8); /* Dark grey with transparency */
}

.dark .data-table tbody tr:nth-child(even) {
    background-color: rgba(58, 58, 58, 0.8); /* Slightly lighter grey for even rows */
}

.dark .data-table tbody tr:hover {
    background-color: rgba(5, 34, 7, 0.8); /* Slightly lighter hover effect */
}
