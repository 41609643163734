/* Dialog box container styling */
.dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    animation: fadeIn 0.3s ease-in-out;
}
.card {
    position: relative;
    min-width: 300px;
    padding: 15px;
    margin: 5px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.card .title {
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    transition: color 0.3s ease;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
}

.card .value {
    /*font-size: 18px;*/
    margin: 10px 0;
}

.card .change {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    font-weight: bold;
}

.card .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 60px;
    height: 60px;
    opacity: 0.2;
    transform: translateY(-50%);
}
/* Table Title */
.table-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #333; /* Default color */
    background-color: rgba(255, 255, 255, 0.6); /* Translucent white background */
    padding: 10px 15px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: inline-block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.dark .table-title {
    color: #070606;
    background-color: rgba(30, 37, 41, 0.8); /* Dark translucent background */
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.2);
}
.light .table-title {
    color: #070606;
    background-color: rgba(30, 37, 41, 0.8); /* Dark translucent background */
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.2);
}

/* Overlay for dialog box */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Dialog box header */
.dialog-header {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
}

/* Dialog box body */
.dialog-body {
    font-size: 1em;
    margin-bottom: 20px;
    color: #555;
    text-align: center;
}

/* Dialog box footer with buttons */
.dialog-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* Button styling in dialog box */
.dialog-footer button {
    padding: 10px 20px;
    font-size: 0.9em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dialog-footer button.primary {
    background-color: #28a745;
    color: #fff;
}

.dialog-footer button.primary:hover {
    background-color: #218838;
}

.dialog-footer button.secondary {
    background-color: #dc3545;
    color: #fff;
}

.dialog-footer button.secondary:hover {
    background-color: #c82333;
}

/* Fade-in animation for dialog box */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -55%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
