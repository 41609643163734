/* PendingApproval.css */
.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    flex-direction: column;
    margin-left: 0px;
    transition: margin-left 0.5s ease;
}

.sidebar-collapsed ~ .container {
    margin-left: 70px; /* Adjust this value according to the collapsed sidebar's width */
}

.data-table {
    width: calc(100% - 250px); /* Adjust width based on sidebar width */
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: width 0.5s ease; /* Smooth transition when sidebar opens or closes */
}

.sidebar-collapsed ~ .data-table {
    width: calc(100% - 70px); /* Adjust based on the collapsed sidebar width */
}


h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 20px 0;
}

.column-id {
    width: 200px; /* Custom width */
}

.loading, .error {
    text-align: center;
    font-size: 1.5rem;
}

.data-table {
    width: 80%;
    border-spacing: 0; /* Ensure no extra space around cells */
    border-collapse: separate; /* Allow rounding of corners */
    border-radius: 15px; /* Rounded corners for the entire table */
    overflow: hidden; /* Ensure contents are clipped at rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.table-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
    padding: 10px 0;
    background-color: #f8f9fa;
}

.data-table th, .data-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
    transition: background-color 0.2s ease-in-out;
}

/* Header style */
.data-table th {
    background-color: #007BFF; /* Primary color (blue) */
    color: white; /* White text */
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    border: none; /* No border for cleaner look */
}

/* Row colors */
.data-table tbody tr {
    background-color: #f8f9fa; /* Light gray background for rows */
}

.data-table tbody tr:nth-child(even) {
    background-color: #e9ecef; /* Slightly darker gray for alternating rows */
}

/* Hover effect for rows */
.data-table tbody tr:hover {
    background-color: #d1ecf1;
}

/* Highlight selected row (optional feature) */
.data-table tbody tr:active {
    background-color: #c3e6cb;
}

@media (max-width: 768px) {
    .data-table {
        width: 100%;
    }
}

h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

p {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.card {
    position: relative;
    width: 250px;;
    padding: 15px; /* Slightly reduce padding */
    margin: 5px; /* Reduce margin to decrease space between cards */
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.data{
    justify-content: flex-start; /* Align cards closer to the left */
    flex-wrap: wrap;
    /*padding: 20px;*/
    /*margin: 0 10px;*/
    /*width: 200px;*/
    text-align: center;
    position: relative;
    display: flex;
    /*flex-wrap: wrap;*/
    gap: 15px;
}
.todo-list-container {
    flex: 1; /* Take up remaining space */
    padding: 20px;
    box-sizing: border-box; /* Include padding in width/height calculation */
    align-items: inherit;
    justify-content: right;
    text-align: right;
}
.chart{
    display: inline-block;
}
.card {
    flex: 1 1 200px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-width: 1200px;
    padding: 20px;
    color: white;
    position: relative;
    text-align: left;
}
.card .title {
    font-size: 20px;
}

.card .value {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
}

.card .change {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    font-weight: bold;
}

.card .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 60px;
    height: 60px;
    opacity: 0.2;
    transform: translateY(-50%);
}
.summary-container {
    transition: margin-left 0.3s ease;
}



.data {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    position: relative;
    width: 250px;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 24px;
}

.todo-list-container {
    margin-top: 30px;
}