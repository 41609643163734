.sign-up-two::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.sign-up-two form {
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sign-up-two input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
}

.sign-up-two label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #333;
    font-size: 1em;
}

/* Submit button styling */
.sign-up-two button[type="submit"] {
    background-color: #ff7225;
    color: #fff;
    border: none;
    text-align: center;
    justify-content: center;
    width: 160px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 12px;
    border-radius: 25px;
    font-size: 1em;
    align-self: center;
}

/* Hover effect for the submit button */
.sign-up-two button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Error message styling */
.error {
    color: #d9534f;
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.9em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sign-up-two form {
        padding: 15px;
        margin: 30px auto;
    }

    .sign-up-two button[type="submit"] {
        padding: 10px;
        font-size: 0.95em;
    }
}
