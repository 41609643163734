/* Card container */
.employee-card {
  max-width: 600px;
  margin: 2rem auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Card header with image */
.employee-card-header {
  width: 100%;
  background-color: #f4f4f4;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

/* Employee image */
.employee-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

/* Card body for employee details */
.employee-card-body {
  padding: 2rem;
  text-align: center;
}

.employee-card-body h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
}

.employee-card-body p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #666;
}

.employee-card-body p strong {
  color: #333;
  font-weight: 600;
}

/* Form styling */
.employee-edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%; /* Ensures form takes the full width of the card */
  padding: 2rem;
  text-align: left; /* Left-align the form fields */
}

/* Form rows for input fields */
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-row label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  color: #333;
}

input[type="text"],
input[type="number"],
input[type="date"] {
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

/* Button row for Edit and Cancel buttons */
.button-row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
}

button[type="button"] {
  background-color: #f44336;
  color: white;
}
