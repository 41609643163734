
.card {
    position: relative;
    width: 250px;
    padding: 15px;
    margin: 5px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.data{
    background: url("../../assets/images/bg.jpg") center;
    background-size: 40%;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    display: flex;
    gap: 15px;
}

.card .title {
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    transition: color 0.3s ease;
}